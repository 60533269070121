if (typeof app == 'object') {
    app.lang.en = {
        notices: {
            attention: {
                title: 'Attention',
                icon: 'fa fa-exclamation-circle',
                type: BootstrapDialog.TYPE_PRIMARY,
            },
            unauthorized: {
                title: 'Unauthorized',
                message: 'You must login before you can complete this action.',
                icon: 'fa fa-warning',
                type: BootstrapDialog.TYPE_DANGER,
            },
            forbidden: {
                title: 'Forbidden',
                message: 'You do not have the required permissions to complete this action.',
                icon: 'fa fa-warning',
                type: BootstrapDialog.TYPE_DANGER,
            },
            pageNotFound: {
                title: 'Page Not Found',
                message: 'The request you made could not be completed.',
                icon: 'fa fa-warning',
                type: BootstrapDialog.TYPE_DANGER,
            },
            undefinedError: {
                title: 'Attention',
                message: 'An unexpected error occurred. Please refresh and try again.',
                icon: 'fa fa-warning',
                type: BootstrapDialog.TYPE_DANGER,
            },
        },
        confirmations: {
            title: 'Please confirm',
            confirm: 'Are you sure you want to do this?',
            remove: 'Are you sure you want to remove this?',
            unload: 'There are unsaved changes. Data that you have entered may not be saved.'
        },
        sessionTimeout: {
            icon: 'fa fa-user',
            title: 'Please login to continue',
            form: '',
            success:{
                icon: 'fa fa-check',
                title: 'Success',
                message: 'You have successfully logged in...',
            },
            button: {
                login: {
                    text: 'login'
                },
            }
        },
        FileManager: {
            title: 'File Manager'
        },
        button: {
            text: {
                yes: 'yes',
                no: 'no',
                cancel: 'cancel',
                close: 'close',
                'continue': 'continue',
                'return': 'return',
                exit: 'exit',
                continueEditing: 'continue editing'
            }
        },
        ipInfo: {
            title: 'IP Address Details'
        },
        copyToClipboard: {
            title: {
                ready: 'Copy to Clipboard',
                success: 'Success!'
            },
            instructions: {
                mac: 'Press Cmd + C to copy to your clipboard',
                'default': 'Press Ctrl + C to copy to your clipboard'
            },
            copied: 'Copied to clipboard!'
        },
        editable: {
            empty: 'empty',
            sourceError: 'Error when loading list'
        },
        growl: {
            error: {
                title: 'Warning'
            },
            warning: {
                title: 'Warning'
            },
            success: {
                title: 'Attention'
            },
            info: {
                title: 'Attention'
            },
            notice: {
                title: 'Attention'
            }
        }
    };
}
