app.pluploadEvents = {
    FileUploaded: 'pluploadFileUploaded',
    UploadComplete: 'pluploadUploadComplete',
    UploadError: 'pluploadUploadError'
};
app.pluploadLang = {
    noFileSelected: {
        title: 'No File Selected',
        description: 'Browse for file to upload or drag and drop it here'
    },
    noFilesSelected: {
        title: 'No Files Selected',
        description: 'Browse for files to upload or drag and drop them here'
    },
    table: {
        head: {
            fileName: 'File Name',
            size: 'Size',
            progress: 'Progress'
        },
        foot: {
            queuedFiles: 'Queued Files',
            uploadedFiles: 'Uploaded Files',
            failedFiles: 'Failed Files',
            totalSize: 'Total Size',
            totalSizeUploaded: 'Uploaded'
        }
    },
    buttons: {
        browse: 'Browse Files',
        upload: 'Upload'
    },
    files: {
        remove: 'Remove',
        done: 'Done',
        hide: 'Hide'
    },
    errors: {
        HTTP_ERROR: {
            label: 'protocol error occured',
            description: ''
        },
        IO_ERROR: {
            label: 'i/o error occured',
            description: ''
        },
        SECURITY_ERROR: {
            label: 'security error occured',
            description: ''
        },
        INIT_ERROR: {
            label: 'initialization error occured',
            description: ''
        },
        FILE_SIZE_ERROR: {
            label: 'file size exceeds limit',
            description: ''
        },
        FILE_EXTENSION_ERROR: {
            label: 'invalid file type',
            description: ''
        },
        FILE_DUPLICATE_ERROR: {
            label: 'duplicate file',
            description: ''
        },
        IMAGE_FORMAT_ERROR: {
            label: 'unsupported image format',
            description: ''
        },
        MEMORY_ERROR: {
            label: 'memory error occured',
            description: ''
        },
        IMAGE_DIMENSIONS_ERROR: {
            label: 'incorrect image dimensions',
            description: ''
        },
        GENERIC_ERROR: {
            label: 'Unknown error occurred',
            description: ''
        }
    }
};

app.fn.plupload = function (input) {
    if(!window['plupload']) {
        app.fn.log('app.fn.plupload: failed to locate Plupload dependency.');
        return null;
    }

    var self = this;
    var $input = $(input).hide().data('plupload', self).attr('disabled', true);
    var $form = $input.parents('form').first();

    var name = this.name = $input.attr('name');
    var isMultiple = this.isMultiple = !!$input.attr('multiple');
    var uploadUrl = this.uploadUrl = $input.data('uploadUrl') || $form.attr('action');
    var maxFileSize = this.maxFileSize = $input.data('maxFileSize') || this.maxFileSize;
    var chunkSize = this.chunkSize = $input.data('chunkSize') || this.chunkSize;
    var allowDuplicateFiles = this.allowDuplicateFiles = $input.data('allowDuplicateFiles') || this.allowDuplicateFiles || true;
    var disableBrowseButtonOnUpload = this.disableBrowseButtonOnUpload = $input.data('disableBrowseButtonOnUpload') || this.disableBrowseButtonOnUpload || false;

    var $container = this.$container = $input.wrap('<div class="panel panel-default plupload-bootstrap"/>').parent().data('plupload', this);

    var $info = this.$info = $('<div class="panel-body text-center"/>').html('<p><strong>'+app.pluploadLang[isMultiple ? 'noFilesSelected' : 'noFileSelected']['title']+'</strong></p><p>'+app.pluploadLang[isMultiple ? 'noFilesSelected' : 'noFileSelected']['description']+'</p>').appendTo($container);
    var $table = this.$table = $('<table class="table table-condensed"/>').prependTo($container).hide();
    var $thead = this.$thead = $('<thead/>').html('<tr>'+
            '<th class="col-sm-5">'+app.pluploadLang['table']['head']['fileName']+'</th>'+
            '<th class="col-sm-3">'+app.pluploadLang['table']['head']['size']+'</th>'+
            '<th class="col-sm-3">'+app.pluploadLang['table']['head']['progress']+'</th>'+
            '<th class="col-sm-1" />'+
        '</tr>').appendTo($table);
    var $tbody = this.$tbody = $('<tbody/>').appendTo($table);
    var $tfoot = this.$tfoot = $('<tfoot/>').html('<tr>'+
            '<td class="plupload-col-total-files">'+
                '<small class="plupload-total-files-queued js-add-tooltip" title="'+app.pluploadLang['table']['foot']['queuedFiles']+'"></small> / ' +
                '<small class="plupload-total-files-uploaded js-add-tooltip text-success" title="'+app.pluploadLang['table']['foot']['uploadedFiles']+'"></small> / ' +
                '<small class="plupload-total-files-failed js-add-tooltip text-warning" title="'+app.pluploadLang['table']['foot']['failedFiles']+'"></small>' +
            '</td>' +
            '<td class="plupload-col-total-size">' +
                '<small class="plupload-total-size-uploaded js-add-tooltip text-success" title="'+app.pluploadLang['table']['foot']['totalSizeUploaded']+'"></small> / ' +
                '<small class="plupload-total-size js-add-tooltip" title="'+app.pluploadLang['table']['foot']['totalSize']+'"></small>' +
            '</td>' +
            '<td class="plupload-col-total-progress">' +
                '<div class="progress">' +
                    '<div class="plupload-total-progress-bar progress-bar progress-bar-primary progress-bar-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">' +
                        '<span class="sr-only"/>' +
                    '</div>' +
                '</div>' +
            '</td>'+
            '<td class="plupload-col-total-speed">'+
                '<small class="plupload-total-speed"></small>' +
            '</td>'+
        '</tr>').appendTo($table);
    var $totalFilesQueued = this.$totalFilesQueued = $('.plupload-total-files-queued', $tfoot);
    var $totalFilesUploaded = this.$totalFilesUploaded = $('.plupload-total-files-uploaded', $tfoot);
    var $totalFilesFailed = this.$totalFilesFailed = $('.plupload-total-files-failed', $tfoot);
    var $totalSize = this.$totalSize = $('.plupload-total-size', $tfoot);
    var $totalSizeUploaded = this.$totalSizeUploaded = $('.plupload-total-size-uploaded', $tfoot);
    var $totalProgressBar = this.$totalProgressBar = $('.plupload-total-progress-bar', $tfoot);
    var $totalSpeed = this.$totalSpeed = $('.plupload-total-speed', $tfoot);

    var panelFooter = $('<div class="panel-footer"/>').appendTo($container);

    var browseButtonText = $input.data('browseButtonText') || app.pluploadLang['buttons']['browse'];
    var $browseButton = this.$browseButton = $('<button class="btn btn-default" />').html('<i class="fa fa-th-list"/> '+browseButtonText).appendTo(panelFooter);

    var uploadButtonText = $input.data('uploadButtonText') || app.pluploadLang['buttons']['upload'];
    var $uploadButton = this.$uploadButton = $('<button class="btn btn-primary pull-right" disabled />').html('<i class="fa fa-upload"/> '+uploadButtonText).appendTo(panelFooter).hide();

    var progressBarTemplate = '<div class="progress">' +
            '<div class="progress-bar progress-bar-primary progress-bar-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">' +
                '<span class="sr-only"/>' +
            '</div>' +
        '</div>';

    var $uploadFunction = this.$uploadFunction = function (event) {
        if(event) {
            event.preventDefault();
        }

        if($uploadButton.is(':disabled')) {
            return false;
        }

        $uploadButton.disable(true);
        if(disableBrowseButtonOnUpload) {
            $browseButton.disable(true);
        }

        responses = self.responses = [];

        uploader.start();
    };

    var uploader = this.uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,html4',
        chunk_size: chunkSize,
        container: $container.get(0),
        drop_element: $container.get(0),
        browse_button: $browseButton.get(0),
        file_data_name: name,
        multi_selection: isMultiple,
        url: uploadUrl,
        max_file_size: maxFileSize,
        flash_swf_url: app.fn.getURL('js/plugins/plupload/plupload.flash.swf'),
        silverlight_xap_url: app.fn.getURL('js/plugins/plupload/plupload.silverlight.xap'),
        filters: {
            prevent_duplicates: allowDuplicateFiles /* this should be !allowDuplicateFiles; but related code needs to be tested */
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Accept': 'application/json'
        }
    });

    var responses = this.responses = [];

    uploader.bind('Init', initHandler);
    uploader.bind('FilesAdded', filesAddedHandler);
    uploader.bind('FilesRemoved', filesRemovedHandler);
    uploader.bind('QueueChanged', queueChangedHandler);
    uploader.bind('FileUploaded', fileUploadedHandler);
    uploader.bind('StateChanged', stateChangedHandler);
    uploader.bind('BeforeUpload', beforeUploadHandler);
    uploader.bind('UploadProgress', uploadProgressHandler);
    uploader.bind('FileUploaded', fileUploadedHandler);
    uploader.bind('UploadComplete', uploadCompleteHandler);
    uploader.bind('Error', uploadErrorHandler);

    function initHandler(uploader, params){
        if(uploader.features.dragdrop) {
            var dragTimer;
            $container.on('dragover',function(e){
                var dt = e.originalEvent.dataTransfer;
                e.preventDefault();
                if(dt.types != null && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('application/x-moz-file'))) {
                    $container.addClass('plupload-file-dragdrop');
                    window.clearTimeout(dragTimer);
                }
            });
            $container.on('dragleave',function(e){
                e.preventDefault();
                dragTimer = window.setTimeout(function() {
                    $container.removeClass('plupload-file-dragdrop');
                }, 25);
            });
            $container.on('drop',function(e) {
                $container.removeClass('plupload-file-dragdrop');
            });
        }
        $input.trigger('plupload-init').unbind('plupload-init').off('plupload-init');
    };

    function addFileTableRow(file) {
        $tbody.append('<tr id="' + file.id + '">' +
            '<td class="plupload-col-filename">' +
            '<i class="fa '+app.fn.getFontAwesomeFileClass(file.type)+'"></i> ' +
            file.name +
            '</td>' +
            '<td class="plupload-col-size">' +
            plupload.formatSize(file.size) +
            '</td>' +
            '<td class="plupload-col-progress">' +
            progressBarTemplate +
            '</td>' +
            '<td class="plupload-col-close text-center">' +
            '<button class="btn btn-xs btn-danger js-add-tooltip" title="'+app.pluploadLang['files']['remove']+'">' +
            '<i class="fa fa-times"></i>' +
            '</button>' +
            '</td>' +
            '</tr>');
        app.fn.initializePlugins($('tr#'+file.id));
    }

    function filesAddedHandler(uploader, files) {
        $info.hide();
        $table.show();
        $uploadButton.disable(false).show();

        for(var i = 0; i < files.length; i++){
            var file = files[i];
            addFileTableRow(file);
        }

        if(!isMultiple && uploader.files.length > 1) {
            uploader.splice(1);
        }
    };

    function filesRemovedHandler(uploader, files) {
        var removedFiles = files;

        for (var i = 0, length = removedFiles.length; i < length; i++) {
            var fileTableRow = $('#' + removedFiles[i].id, $tbody);
            $('.js-add-tooltip', fileTableRow).tooltip('destroy');
            fileTableRow.remove();
        }
    };

    function refreshDisplayFileTotals(uploader) {
        $totalSizeUploaded.html(plupload.formatSize(uploader.total.loaded));
        $totalSize.html(plupload.formatSize(uploader.total.size));
        $totalFilesQueued.html(uploader.total.queued);
        $totalFilesUploaded.html(uploader.total.uploaded);
        $totalFilesFailed.html(uploader.total.failed);
    }

    function refreshTotalProgressDisplay(uploader) {
        $totalProgressBar.show().css('width', uploader.total.percent + '%').attr('aria-valuenow',uploader.total.percent);
        $('.sr-only', $totalProgressBar).html(uploader.total.percent + '%');
        $totalSpeed.html(plupload.formatSize(uploader.total.bytesPerSec)+'/s');
    }

    function refreshFileProgressDisplay(uploader, file) {
        var $row = $('#' + file.id, $tbody);
        var progressBar = $('.progress-bar', $row);

        if(!progressBar.size()) {
            $('.plupload-col-progress', $row).html(progressBarTemplate);
            progressBar = $('.progress-bar', $row);
        }
        progressBar.css('width', file.percent + '%').attr('aria-valuenow',file.percent);
        $('.sr-only',progressBar).html(file.percent + '%');
    }

    function resetUploadProgressDisplay(uploader) {
        $totalProgressBar.hide().css('width', 0).attr('aria-valuenow', 0);
        $('.sr-only',$totalProgressBar).html('');
        $totalSpeed.html('');
    }

    function queueChangedHandler(uploader) {
        setTimeout(function() { refreshDisplayFileTotals(uploader); }, 50);
    };

    function stateChangedHandler(uploader) {
        setTimeout(function() { refreshDisplayFileTotals(uploader); }, 50);
    };

    function beforeUploadHandler(uploader, file) {
        $input.setDirtyState();
        var multipartParams = {};
        var fields = $form.serializeArray();

        for (var i = 0, length = fields.length; i < length; i++) {
            var field = fields[i];
            var fieldName = field.name;

            if(fieldName) {
                multipartParams[fieldName] = field.value;
            }
        }
        uploader.settings.multipart_params = multipartParams;
        refreshFileProgressDisplay(uploader, file);
    }


    function uploadProgressHandler(uploader, file) {
        refreshFileProgressDisplay(uploader, file);
        refreshTotalProgressDisplay(uploader);
    }


    function fileUploadedHandler(uploader, file, response) {
        var serverResponse = jQuery.parseJSON(response.response);
        if(!serverResponse.error) {
            var fileTableRow = $('#' + file.id, $tbody);
            $('.progress-bar', fileTableRow).removeClass('progress-bar-primary progress-bar-striped active').addClass('progress-bar-success').attr('aria-valuenow', 100).html(app.pluploadLang['files']['done']);
            $('.plupload-col-close button', fileTableRow).removeClass('btn-danger').addClass('btn-default').attr('title', app.pluploadLang['files']['hide']);

            responses.push(response);
            $input.trigger($.Event(app.pluploadEvents.FileUploaded, {
                uploader: uploader,
                file: file,
                response: response
            }));
        } else {
            file.status = plupload.FAILED;
            serverResponse.error.file = file;
            uploader.trigger('Error', serverResponse.error);
        }

        refreshDisplayFileTotals(uploader);
    }


    function uploadCompleteHandler(uploader, files) {
        $input.trigger($.Event(app.pluploadEvents.UploadComplete, {
            uploader: uploader,
            files: files,
            responses: responses
        }));
        $uploadButton.disable(true).hide();
        if(disableBrowseButtonOnUpload) {
            $browseButton.disable(false);
        }
        resetUploadProgressDisplay(uploader);
        $input.clearDirtyState();
    }

    function uploadErrorHandler(uploader, error) {
        var fileTableRow = $('#' + error.file.id, $tbody).addClass('error');
        var message;

        switch(error.code) {
            case plupload.HTTP_ERROR:
                message = app.pluploadLang['errors']['HTTP_ERROR'];
                break;
            case plupload.IO_ERROR:
                message = app.pluploadLang['errors']['IO_ERROR'];
                break;
            case plupload.SECURITY_ERROR:
                message = app.pluploadLang['errors']['SECURITY_ERROR'];
                break;
            case plupload.INIT_ERROR:
                message = app.pluploadLang['errors']['INIT_ERROR'];
                break;
            case plupload.FILE_SIZE_ERROR:
                message = app.pluploadLang['errors']['FILE_SIZE_ERROR'];
                break;
            case plupload.FILE_EXTENSION_ERROR:
                message = app.pluploadLang['errors']['FILE_EXTENSION_ERROR'];
                break;
            case plupload.FILE_DUPLICATE_ERROR:
                message = app.pluploadLang['errors']['FILE_DUPLICATE_ERROR'];
                break;
            case plupload.IMAGE_FORMAT_ERROR:
                message = app.pluploadLang['errors']['IMAGE_FORMAT_ERROR'];
                break;
            case plupload.MEMORY_ERROR:
                message = app.pluploadLang['errors']['MEMORY_ERROR'];
                break;
            case plupload.IMAGE_DIMENSIONS_ERROR:
                message = app.pluploadLang['errors']['IMAGE_DIMENSIONS_ERROR'];
                break;
            case plupload.GENERIC_ERROR:
            default:
                message = app.pluploadLang['errors']['GENERIC_ERROR'];
                break;
        }

        var progressCol = $('.plupload-col-progress',fileTableRow);
        progressCol.html(message.label).attr('title',message.description).addClass('text-danger js-add-tooltip');
        app.fn.initializePlugins(progressCol);

        if(uploader.files.length === 0) {
            $uploadButton.disable(true).hide();
        }

        $input.trigger($.Event(app.pluploadEvents.UploadError, {
            uploader: uploader,
            error: error
        }));
    }


    $tbody.on('click', '.plupload-col-close button', function (e) {
        e.preventDefault();

        var fileTableRow = $(this).parents('tr').first();
        var id = fileTableRow.attr('id');
        var file = uploader.getFile(id);

        if (uploader.total.queued <= 1 && $('tr:visible',$tbody).length <= 1) {
            $info.show();
            $table.hide();
            $browseButton.show();
            $uploadButton.disable(true).hide();
        }

        if(file){
            switch(file.status) {
                case plupload.QUEUED:
                    uploader.removeFile(file);
                    break;
                case plupload.UPLOADING:
                    uploader.removeFile(file);
                    if(plupload.STARTED) {
                        uploader.stop();
                        uploader.start();
                    }
                    break;
                case plupload.DONE:
                    uploader.removeFile(file);
                    break;
            }
        } else {
            fileTableRow.remove();
        }
    });

    $uploadButton.bind('click.plupload', $uploadFunction);
    app.fn.initializePlugins($container);
    uploader.init();
};

app.fn.plupload.prototype = {
    $container: null,
    $info: null,
    $table: null,
    $thead: null,
    $tbody: null,
    $tfoot: null,
    $totalFilesQueued: null,
    $totalFilesUploaded: null,
    $totalFilesFailed: null,
    $totalSize: null,
    $totalSizeUploaded: null,
    $totalProgressBar: null,
    $totalSpeed: null,
    $browseButton: null,
    $uploadButton: null,
    $uploadFunction: null,
    name: null,
    isMultiple: false,
    uploadUrl: null,
    maxFileSize: false,
    chunkSize: 0,
    uploader: null,
    responses: null,
    allowDuplicateFiles: true,
    disableBrowseButtonOnUpload: false
};

$(function () {
    $('input.js-plupload[type="file"]').each(function (index, element) {
        new app.fn.plupload(element);
    });
});
